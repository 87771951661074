@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

html {
  font-family: 'Roboto', sans-serif;
}

#root {
  background-color: #CCD8E7;
}

#logo {
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
  flex-direction: row;
  flex-grow: 1;
  padding-bottom: 80px;
}

#logo img {
  width: 56px;
}

.filter-view div div {
  justify-content: flex-start !important;
}

#devicefilter div div {
  justify-content: flex-start !important;	
}

#devicefilter div[data-source=q] {
	flex: 2 1 auto;
}

#devicefilter div[data-source=q] div div {
	min-width: 256px !important;
	width: 560px !important;
}

.tavollet-timeline {
  width: 95%;
  padding-bottom: 10px;
  margin: auto;
}

.tavollet-timeline .column-indok {
  width: 50%;
}

.rct-sidebar-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rct-item-content {
  color: black !important;
}

.fenykepkategoria .column-kategoria {
  width: 50%;
}

.column {
  flex-direction: column;
  display: flex;
}

.column-datum, .column-kategoria {
  white-space: nowrap;
}

.tagselector-root {
  padding-left: 1em;
  flex-direction: column;
  display: flex;
}

.tagselector {
  flex-direction: row;
  display: flex;
}

.tagselector > div {
  flex-grow: 0;
  padding-right: 1em;
}

.kategoriafield {
  flex-direction: row;
  display: flex;
}

.kategoriafield > span {
  padding-right: 0.5em;
}

.kategoriafield > span.source {
  padding-right: 1em;
}

[role="alertdialog"] {
  white-space: pre-line;
}

.napijelentes .ra-input div {
  min-width: 240px;
}

.bejelentestipus-icon {
  height: 32px;
  width: 32px;
}
.previews {
  margin-top: 10px;
}
.latnivalo-thumb {
  width: 200px;
  vertical-align: top;
  margin-bottom: 10px;
}

.bejelentestipus-preview {
  vertical-align: middle;
}

.BejelentesTipus .column-nev {
  width: 80%;
}

.latnivalo-cim {
  width: 80%;
}